import cn from 'classnames';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { submitPickYourGiftStep } from '../../store/actions/form';
import { selectPickedCampaignId } from '../../store/selectors/form';
import { selectCampaigns } from '../../store/selectors/init';

import CampaignItem from '../../components/CampaignItem/CampaignItem';
import RadioButton from '../../components/RadioButton/RadioButton';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { isCampaignBlocked } from '../../util/helpers';

import mainStyles from '../../views/App/App.module.scss';
import styles from './PickYourGift.module.scss';

const PickYourGift = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaignList = useSelector(selectCampaigns);
  const selectedCampaign = useSelector(selectPickedCampaignId);

  const [selectedCampaignId, setSelectedCampaign] = React.useState<string | null | undefined>(selectedCampaign);

  const shouldShowItemsCentered = React.useMemo(() => campaignList?.length === 2, [campaignList]);

  const handlePickCampaign = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;

    setSelectedCampaign((prev) => (prev === id ? undefined : id));
  }, []);

  const handleContinue = React.useCallback(
    (selectedId: string | null | undefined) =>
      new Promise<string>((resolve) => {
        if (selectedId) {
          dispatch(
            submitPickYourGiftStep({
              campaignId: selectedId,
              resolve,
            }),
          );
        }
      }).then(history.push),
    [history, campaignList, dispatch],
  );

  if (!campaignList) {
    return null;
  }

  return (
    <Container className={mainStyles.AppWrapper}>
      <Row className={styles.pygContainer}>
        <Col xs={12}>
          <Row className={cn(styles.optionList, { [styles.centeredList]: shouldShowItemsCentered })}>
            {campaignList?.map((campaign) => {
              const isPicked = selectedCampaignId === campaign.id;
              const isDimmed = !!selectedCampaignId && !isPicked;
              const isDigital = !!campaign?.is_digital;
              const isDisabled = !isDigital && isCampaignBlocked(campaign);

              return (
                <Col sm={12} md={6} lg={6} xl={4} className={cn(styles.option)} key={campaign.id}>
                  <RadioButton
                    name={campaign.id}
                    className={styles.radio}
                    containerClassName={styles.radioContainer}
                    value={selectedCampaignId || ''}
                    defaultValue={campaign.id}
                    onChange={handlePickCampaign}
                    disabled={isDisabled}
                    label={
                      <CampaignItem
                        isDigital={isDigital}
                        isPicked={isPicked}
                        items={campaign.items}
                        title={campaign?.name}
                        isDimmed={isDimmed}
                        shouldShowPickButton
                        isDisabled={isDisabled}
                      />
                    }
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row className={styles.continueRow}>
        <SubmitButton onSubmit={() => handleContinue(selectedCampaignId)} disabled={!selectedCampaignId}>
          Continue
        </SubmitButton>
      </Row>
    </Container>
  );
};

export default PickYourGift;
