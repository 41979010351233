import { useMemo } from 'react';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { routes } from '../../constants/routes';
import { CUSTOMIZE_STEP_BODY_TEXT } from '../../constants/ui';
import { selectGreetingTitle, selectPrioritizedWL, selectSenderWLData } from '../../store/selectors/derived';
import HtmlView from '../HtmlView/HtmlView';

import styles from './Greeting.module.scss';

interface IProps {
  className?: string;
  isMysteryBox?: boolean;
}

const Greeting = ({ className, isMysteryBox }: IProps) => {
  const { pathname } = useLocation();

  const { sender_company_name, sender_first_name, sender_last_name } = useSelector(selectSenderWLData) || {};
  const title = useSelector(selectGreetingTitle(pathname));
  const { pick_your_gift_body, customize_items_page_body, msku_page_body, shipping_page_body } =
    useSelector(selectPrioritizedWL);

  const defaultMysteryBoxPygBody = React.useMemo(() => {
    return (
      <p className={styles.body}>
        Your friends at <span className={styles.sender}>Imprint Engine </span>
        want to give you a gift.
        <br />
        We promise only mild disappointment if you pick poorly.
      </p>
    );
  }, []);

  const defaultPygBody = React.useMemo(() => {
    return (
      <p className={styles.body}>
        You are about to receive a gift from{' '}
        <span className={styles.sender}>
          {sender_first_name} from {sender_company_name}
        </span>
        <br />
        Select one of the following packs you want to receive.
      </p>
    );
  }, [sender_first_name, sender_company_name]);

  const getPygBody = useMemo(() => {
    if (isMysteryBox) {
      return defaultMysteryBoxPygBody;
    }

    if (pick_your_gift_body) {
      return <HtmlView html={pick_your_gift_body} className={styles.customBodyContainer} />;
    }

    return defaultPygBody;
  }, [isMysteryBox, pick_your_gift_body, defaultPygBody, defaultMysteryBoxPygBody]);

  const defaultShippingPageBody = React.useMemo(() => {
    return (
      <>
        <p>To make sure you get this gift, please confirm your preferred ship-to address and it will be on its way!</p>

        <div className={styles.sender}>
          {`${sender_first_name} ${sender_last_name}`} with {sender_company_name}
        </div>
      </>
    );
  }, [sender_first_name, sender_last_name, sender_company_name]);

  const displayedBody = React.useMemo(() => {
    switch (pathname) {
      case routes.pyg:
        return getPygBody;
      case routes.pickMsku:
        return msku_page_body ? <HtmlView html={msku_page_body} className={styles.customBodyContainer} /> : null;
      case routes.customizeItems:
        return customize_items_page_body ? (
          <HtmlView html={customize_items_page_body} className={styles.customBodyContainer} />
        ) : (
          CUSTOMIZE_STEP_BODY_TEXT
        );
      case routes.address:
        return shipping_page_body ? (
          <HtmlView html={shipping_page_body} className={styles.customBodyContainer} />
        ) : (
          defaultShippingPageBody
        );
      default:
        return null;
    }
  }, [getPygBody, pathname, shipping_page_body, msku_page_body, customize_items_page_body, defaultShippingPageBody]);

  return (
    <Col xs={12} className={className}>
      <h1 className={styles.title}>{isMysteryBox ? 'Choose Your Own Adventure' : title}</h1>
      {displayedBody}
    </Col>
  );
};

export default Greeting;
