import { useMemo } from 'react';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import { FOOTER_DISCLAIMER } from '../../constants/ui';
import { initApp } from '../../store/actions/init';
import { selectAccentColor, selectBaseColor, selectIsLoading } from '../../store/selectors/init';
import { selectInitKeys, selectIsInitialized } from '../../store/selectors/keys';
import { isAuthenticationRoute, isResultRoute, setAccentColor, setBaseColor } from '../../util/helpers';

import styles from './App.module.scss';

interface IProps {
  children: React.ReactNode;
}

function App({ children }: IProps) {
  const { search: initString, pathname } = useLocation();

  const dispatch = useDispatch();
  const isInitialized = useSelector(selectIsInitialized);
  const isLoading = useSelector(selectIsLoading);
  const baseColor = useSelector(selectBaseColor);
  const accentColor = useSelector(selectAccentColor);
  const keys = useSelector(selectInitKeys);

  const mysteryBoxIDs = process.env.REACT_APP_MYSTERY_BOX_IDS ? process.env.REACT_APP_MYSTERY_BOX_IDS.split(',') : [];

  React.useEffect(() => {
    if (!isInitialized) {
      dispatch(initApp(initString));
    }
  }, [dispatch, initString, isInitialized]);

  React.useEffect(() => {
    if (baseColor) {
      setBaseColor(baseColor);
    }
    if (accentColor) {
      setAccentColor(accentColor);
    }
  }, [accentColor, baseColor]);

  const isMysteryBox = useMemo(() => {
    if (keys && keys.one_link_id) {
      return mysteryBoxIDs.includes(keys.one_link_id);
    }
  }, [mysteryBoxIDs, keys]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      {isLoading && <Loader isLoading />}
      <React.Fragment>
        {!isResultRoute(pathname) && !isAuthenticationRoute(pathname) && <Header isMysteryBox={isMysteryBox} />}
        {children}
        {!isResultRoute(pathname) && !isAuthenticationRoute(pathname) && (
          <React.Fragment>
            <Container>
              <Row>
                <Col xs={12} className={styles.safetyMessage}>
                  {FOOTER_DISCLAIMER}
                </Col>
              </Row>
            </Container>
            <Footer isMysteryBox={isMysteryBox} />
          </React.Fragment>
        )}
      </React.Fragment>
      <ToastContainer
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.Fragment>
  );
}

App.whyDidYouRender = false;

export default App;
