import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { RESULT_ROUTES, routes } from '../constants/routes';
import { KEY, ONE_LINK_ID, ORDER_ID } from '../constants/shipping';
import { ICustomizableItem, ICustomizableStoredItem, IPickedMskuItem } from '../types/api';
import { ICampaign, ICampaignItem } from '../types/pyg';
import { IInitKeys } from '../types/shipping';

const HASH = '#';
const HEX_REGEX = new RegExp('^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$', 'gm');

export const parseInitQuery = (initString: string): IInitKeys => {
  const { order_id, key, one_link_id } = qs.parse(initString) || {};

  return { order_id, key, one_link_id } as IInitKeys;
};

export const validateInitQuery = (keys: IInitKeys | null) =>
  keys && !!((keys[ORDER_ID] && keys[KEY]) || keys[ONE_LINK_ID]);

export const getColor = (value: string | undefined = '', fallback?: string) => {
  let color = fallback ? fallback : undefined;

  const match = value?.match(HEX_REGEX);

  if (match && match?.length) {
    color = value.startsWith(HASH) ? value : `#${value}`;
  }

  return color;
};

export const getStyleVariableValue = (variable: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable)?.trim();
};

export const setBaseColor = (color: string, element: string = 'root') => {
  return document.getElementById(element)?.style.setProperty('--base-color', color);
};

export const setAccentColor = (color: string, element: string = 'root') => {
  return document.getElementById(element)?.style.setProperty('--accent-color', color);
};

export const isMskuItem = ({ sku_options }: ICampaignItem) => !!(sku_options && sku_options.length);

export const isItemOutOfStock = (item: ICampaignItem) =>
  !item.preorder_allowed && (item.count ?? 0) < (item.quantity ?? 0);

export const isMskuOutOfStock = ({ sku_options, quantity }: ICampaignItem) => {
  const totalCount = sku_options?.reduce((sum, option) => sum + (option?.count ?? 0), 0);
  const hasPreorderAllowed = sku_options?.some((option) => option.preorder_allowed);
  return totalCount && quantity && totalCount < quantity && !hasPreorderAllowed;
};

export const isCampaignBlocked = (campaign: { items?: ICampaignItem[] }) =>
  !!campaign?.items?.some(
    (item) => (!isMskuItem(item) && isItemOutOfStock(item)) || (isMskuItem(item) && isMskuOutOfStock(item)),
  );

export const isCustomizableItem = (item: ICampaignItem) =>
  item.customization && item.customization.item_customization_allowed;

export const isMskuIncluded = ({ items }: ICampaign) => items.some(isMskuItem);

export const isCustomizableItemsIncluded = (items: ICampaignItem[]) => items.some(isCustomizableItem);

export const parseItemsQuantity = (items: ICampaignItem[]) => {
  const mskuItems: ICampaignItem[] = [];

  items.forEach(({ quantity, ...item }) => {
    if (quantity === 1 || !item.sku_options?.length) {
      mskuItems.push({
        ...item,
        internalId: uuidv4(),
        sku_options: item?.sku_options?.map((sku) => ({
          ...sku,
          internalId: uuidv4(),
        })),
        quantity,
      });
    } else {
      for (let i = 0; i < Number(quantity); i++) {
        mskuItems.push({
          ...item,
          sku_options: item.sku_options.map((sku) => ({
            ...sku,
            internalId: uuidv4(),
          })),
          internalId: uuidv4(),
          quantity: 1,
        });
      }
    }
  });

  return mskuItems;
};

export const formatSkuOptions = (items?: ICampaignItem[] | null) => {
  if (typeof items === 'undefined') {
    return null;
  }

  return items ? parseItemsQuantity(items) : [];
};

export const convertInventoryItemsToPickedItems = (items: ICampaignItem[]): { [id: string]: IPickedMskuItem } => {
  return items
    .filter(({ sku_options }) => !!sku_options?.length)
    .reduce((acc, current) => {
      const { internalId } = current;
      return { ...acc, [internalId as string]: null };
    }, {});
};

export const getPickedItemsByDefault = (items: ICampaignItem[]) => {
  return formatSkuOptions(items.filter((item) => !item.sku_options?.length).map((item) => item))?.reduce(
    (acc, current) => {
      const { internalId } = current;
      return {
        ...acc,
        [internalId as string]: {
          quantity: current.quantity,
          uid: current.id,
          parent_id: '',
        },
      };
    },
    {},
  );
};

export const preparePickedItemsForAPI = (
  items?: {
    [id: string]: IPickedMskuItem | null;
  } | null,
): { picked_items: IPickedMskuItem[] } | undefined => {
  if (!items) {
    return;
  }

  // tslint:disable-next-line:variable-name
  const picked_items: IPickedMskuItem[] = [];

  Object.values(items).forEach((pickedItem) => {
    const item = picked_items.find((i) => i.uid === pickedItem?.uid && i.parent_id === pickedItem.parent_id);

    if (item) {
      item.quantity += 1;
    } else if (pickedItem) {
      picked_items.push({ ...pickedItem, quantity: 1 });
    }
  });

  return picked_items && picked_items.length ? { picked_items } : undefined;
};

export const getUniqueItemsWithCounts = (items: (IPickedMskuItem | null)[]) => {
  const uniqueItemIds = Array.from(new Set(items.map((item) => item?.uid)));

  return uniqueItemIds.map((itemId) => {
    const itemsArray = items.filter((item) => item?.uid === itemId);
    const quantity = itemsArray.map((item) => (item ? item.quantity : 0)).reduce((prev, curr) => prev + curr, 0);
    return {
      uid: itemId,
      quantity,
    };
  });
};

export const convertInventoryItemsToCustomizableItems = (
  items: ICampaignItem[],
): { [id: string]: ICustomizableStoredItem } => {
  return items.reduce((acc, current) => {
    // tslint:disable-next-line:variable-name
    const { internalId, id: item_id } = current;
    return { ...acc, [internalId as string]: { internalId, item_id } };
  }, {});
};

export const splitInventoryByQuantity = (items: ICampaignItem[]): ICampaignItem[] => {
  const itemsList = items
    .map((item) => {
      const { quantity = 1, ...campaignItem } = item;
      return Array(quantity).fill(campaignItem) as ICampaignItem[];
    })
    .flat();

  return itemsList.map((item) => {
    return {
      ...item,
      internalId: uuidv4(),
    };
  });
};

export const getCustomizableItemsList = (items: ICampaignItem[] | null): ICampaignItem[] => {
  return (
    items?.filter(
      (item: ICampaignItem) => item.customization?.item_customization_allowed && !item.sku_options?.length,
    ) || []
  );
};

export const convertPickedItemsToInventoryItems = (
  pickedItems: { [id: string]: IPickedMskuItem },
  inventoryItems: ICampaignItem[] | null,
): ICampaignItem[] => {
  const result: ICampaignItem[] = [];

  Object.keys(pickedItems).forEach((internalId) => {
    const mskuItem = inventoryItems?.find(
      (multipleOptionItem: ICampaignItem) => multipleOptionItem.internalId === internalId,
    );
    const skuOption = mskuItem?.sku_options?.find((item: ICampaignItem) => item.id === pickedItems[internalId]?.uid);

    if (skuOption) {
      result.push(skuOption);
    }
  });

  return result;
};

export const prepareCustomizedItemsForRequest = (
  items?: {
    [id: string]: ICustomizableStoredItem;
  } | null,
): { custom_items: ICustomizableItem[] } => {
  const customItems = items ? Object.values(items) : [];
  const customizableItemsList = customItems.map(({ internalId, ...item }) => {
    return item;
  });

  return {
    custom_items: customizableItemsList,
  };
};

export const isResultRoute = (route: string) => RESULT_ROUTES.includes(route);

export const isAuthenticationRoute = (route: string) => routes.authentication === route;
