import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';

import styles from './Stepper.module.scss';

export interface IStep {
  id: string;
  route: string;
  label: string | number;
  isDisabled?: boolean;
}

interface IStepLabel {
  order: number;
  label: string | number;
  isActive: boolean;
  isCurrent: boolean;
}

interface IProps {
  steps: IStep[];
  active?: string;
  className?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  isMysteryBox?: boolean;
}

const Stepper = React.forwardRef(
  ({ active, className, labelClassName, steps, isMysteryBox }: IProps, ref: React.Ref<HTMLOListElement>) => {
    const { mobile, tablet } = useWindowSize();

    const StepLabel = React.useMemo(
      () =>
        ({ order, label, isActive, isCurrent }: IStepLabel) => {
          const shouldDisplayLabel = mobile || tablet ? isCurrent : true;
          const displayedOrder = order + 1;

          return (
            <React.Fragment>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                className={cn(styles.stepCount, {
                  [styles.active]: isActive,
                  [styles.current]: isCurrent,
                })}
              >
                {isMysteryBox ? (
                  <>
                    <g className={styles.stepCircleMystery}>
                      <circle
                        cx="25"
                        cy="25"
                        r="22"
                        mask={!isMysteryBox ? `url(#maskOrder${displayedOrder})` : undefined}
                      />
                    </g>
                    <text x="50%" y="50%" dy=".3em" textAnchor="middle" fontSize="20" fill="#5f11ea">
                      {displayedOrder}
                    </text>
                  </>
                ) : (
                  <>
                    <g className={styles.stepCircle}>
                      <circle
                        cx="25"
                        cy="25"
                        r="22"
                        {...(!isMysteryBox && { mask: `url(#maskOrder${displayedOrder})` })}
                      />
                    </g>
                    <mask id={`maskOrder${displayedOrder}`}>
                      <rect x="0" y="0" width="50" height="50" fill="white" />
                      <text fill="red" x="20" y="31">
                        {displayedOrder}
                      </text>
                    </mask>
                  </>
                )}
              </svg>
              {shouldDisplayLabel && (
                <span className={cn(styles.labelText, labelClassName, { [styles.labelMysteryBox]: isMysteryBox })}>
                  {label}
                </span>
              )}
            </React.Fragment>
          );
        },
      [isMysteryBox, mobile, tablet, labelClassName],
    );

    return (
      <ol className={cn(styles.container, className)} ref={ref}>
        {steps.map(({ id, route, label, isDisabled }, i) => {
          const isActive = active === id || i < steps.findIndex((s) => s.id === active);
          const isCurrent = active === id;

          return (
            <li
              key={id}
              className={cn(styles.stepItem, {
                [styles.active]: isActive,
                [styles.current]: isCurrent,
                [styles.stepItemMystery]: isMysteryBox,
              })}
            >
              {isCurrent || isDisabled ? (
                <span className={styles.label}>
                  <StepLabel key={id} label={label} order={i} isActive={isActive} isCurrent={isCurrent} />
                </span>
              ) : (
                <Link className={styles.label} role="button" to={route}>
                  <StepLabel key={id} label={label} order={i} isActive={isActive} isCurrent={isCurrent} />
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    );
  },
);

export default Stepper;
